import React, { useState } from "react"
import Layout from "@app-components/layout/"
import Feature from "@app-components/ui/feature/"
import Header from "@app-components/ui/header/side_by_side/"
import HeaderImage from "@page-components/products/festivals/media/header/"
import DownloadImage from "@page-components/products/festivals/media/download/"
import TrackUploadImage from "@page-components/products/festivals/media/track-upload/"
import AsperaImage from "@page-components/products/festivals/media/aspera/"
import OrganizedImage from "@page-components/products/festivals/media/organized/"
import HardwareImage from "@page-components/products/festivals/media/hardware/"
import HEADERS from "@data/headers/"
import FestivalsSignupModal from "@page-components/festivals/signup-modal"

const Festivals = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Layout
      getStartedButtons={["demo", "contact-sales"]}
      page="products/festivals"
      getStartedText="Sign up today and become your festival's print traffic hero.">
      <Header
        title="CineSend Inbound"
        text="Start accepting DCPs and other digital file formats electronically for your film festival without the headache of hundreds of different download links."
        image={<HeaderImage />}
        secondaryNavigation={HEADERS.festivals}
        buttons={[
          {
            text: <div className="red">Get Started</div>,
            className: "web_white",
            type: "link",
            onClick: () => setShowModal(true)
          },
          {
            text: "Schedule a Demo",
            className: "web_white_outline ",
            type: "link",
            link: {
              to: "/demo",
              type: "internal"
            }
          }
        ]}
      />
      <Feature
        title="A Single Download Portal"
        text="It's extremely cumbersome to download content from the wide range of file transfer tools that filmmakers use around the world. CineSend Inbound does away with this by providing you with a single portal that you use to download all content."
        icon="download-white"
        image={<DownloadImage />}
      />
      <Feature
        title="Track Upload Status"
        reverse
        text="Monitor uploads in real time. Keep track of who has uploaded and who hasn’t. You can even see which files are in progress, and view estimated completion times. Once a film has uploaded the content you need, you can close the request to prevent further uploads."
        icon="track-upload-white"
        image={<TrackUploadImage />}
      />
      <Feature
        title="Aspera Transfers Built-In"
        text="IBM's Aspera technology facilitates fast and reliable file transfers, and is built right into CineSend. Using Aspera also gives you the control to pause and resume, and ensures that your content remains encrypted during the transfer."
        icon="aspera-white"
        image={<AsperaImage />}
      />
      <Feature
        title="Keep Organized"
        reverse
        text="Color code file requests and save notes so that teammates remain up to date and on the same page. Optionally sort file requests into different “groups” to separate shorts from features, or however else makes sense for your team."
        icon="folder-white"
        image={<OrganizedImage />}
      />
      <Feature
        title="Hardware Options for Large Festivals"
        text="Optionally link your account to a CineSend Media Manager. When a filmmaker uploads a DCP, it will automatically be downloaded and verified by the appliance. Through the Festivals web interface, you copy DCP content to up to 8 CRU drive bays at a time."
        icon="hardware-white"
        image={<HardwareImage />}
      />

      {showModal && <FestivalsSignupModal onClose={() => setShowModal(false)} />}
    </Layout>
  )
}

Festivals.propTypes = {}

export default Festivals
